/* User.css */

.userpage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }


  
  .userItems {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(24, 24, 24);
    padding: 10px;
    gap: 20px;
    width: 100%;
  }
  
  .useritem, .useritemActive {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 20px;
    background-color: #fff;
    color: #333;
    transition: background-color 0.3s ease;
    background-color: var(--col2);
    color: #fff;
    text-align: center;
  }
  
  .useritem:hover {
    background-color: white;
    color:  var(--col1);
  }
  
  .useritemActive {
    background-color: white;
    color:  var(--col1);
  }
  