.home {
    /* width: 100vw; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


.particleBox {
    position: absolute;
    /* max-width: 100vw; */
    max-height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
}


.homerow {
    background-color: rgba(255, 255, 255, 0.581);
    width: 70%;
    min-width: 1200px;
    display: flex;
    margin: 50px auto;
    overflow: hidden;
}


.homerow .homeColumn {
    width: 50%;
}


.homerow .homeColumn img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
}

.pgbig {
    background-color: rgb(46, 46, 46);
    padding: 5% 10%;
    display: flex;
    justify-content: center;
    gap: 50px;
}


.pgbig h1 {
    font-size: 70px;
    margin-bottom: 20px;
    text-align: center;
    color: white;
}

.pgbig p {
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(189, 189, 189);
}

.pgbig span {
    font-size: 25px;
    margin-right: 10px;
    font-weight: 600;
    color: white;
}

.pgbig h2{
    font-size: 30px;
    margin-top: 50px;
    color: white;

}


@media (max-width: 1280px) {
    .homerow {
        min-width: auto;
        width: 90%;
    }

    .homerow .homeColumn img{
        padding-right: 20px;
    }
}

@media (max-width: 800px) {
    .homerow {
       flex-direction: column;
    }

    .homerow .homeColumn {
        width: 100%;
    }

    .homerow .homeColumn img{
        padding: 10px;
    }
}



@media (max-width: 1200px) {
    .pgbig {
        gap: 30px; /* Adjust the gap on smaller screens */
    }

    .pgbig h1 {
        font-size: 50px; /* Decrease the font size for smaller screens */
    }

    .pgbig p {
        font-size: 18px; /* Slightly smaller font size for paragraphs */
    }

    .pgbig span {
        font-size: 20px; /* Decrease the font size for spans */
    }

    .pgbig h2 {
        font-size: 24px; /* Decrease the font size for headings */
    }
}

@media (max-width: 992px) {
    .pgbig {
        padding: 5% 5%; /* Adjust padding for smaller screens */
    }

    .pgbig h1 {
        font-size: 40px; /* Further decrease the font size */
    }

    .pgbig p {
        font-size: 16px; /* Adjust the font size */
    }

    .pgbig span {
        font-size: 18px; /* Adjust the font size */
    }

    .pgbig h2 {
        font-size: 20px; /* Adjust the font size */
    }
}

@media (max-width: 768px) {
    .pgbig {
        flex-direction: column; /* Stack items vertically on very small screens */
        padding: 5% 2%; /* Further reduce padding */
        gap: 20px; /* Adjust gap */
    }



    .pgbig h2{
        text-align: center;
    }

    .pgbig p {
        font-size: 14px; /* Adjust the font size */
    }

    .pgbig span {
        font-size: 16px; /* Adjust the font size */
    }

    .pgbig h2 {
        font-size: 18px; /* Adjust the font size */
    }
}