/* loginpage.css */

.subscriptionpage {
    display: flex;
    /* Center content horizontally */
    flex-direction: column;
    min-height: 100vh;

    /* Set minimum height for full viewport */
}

.subscription-form {
    background-color: #fff;
    width: 500px;
    min-width: 300px;
    /* Adjust width as needed */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Adding box shadow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px; /* Optional: adds rounded corners */
    padding: 20px; /* Optional: adds padding inside the box */
}

.subscription-form h3{
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 300;
    text-align: center;
    /* Center form content */
    color: gray;
    /* padding: 10px; */
}

.subscription-form span{
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    /* Center form content */
    color: var(--col1);
    text-decoration: underline;
    /* padding: 10px; */
}

.subscription-form h2 {
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    /* Center form content */
    background-color: var(--col1);
    color: white;
    padding: 10px;
}

.subscription-form label {
    display: block;
    margin-bottom: 5px;
    width: '100%';
    font-weight: 300;

}

.subscription-form form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.subscription-form .row{
    /* background-color: red; */
    width: 100%;
}
.subscription-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-weight: 400;
}

.subscription-form button {
    background-color: var(--col1);
    /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
}

.subscription-form button:hover {
    background-color: var(--col1);

    /* Darker green on hover */
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}


.subscription-form p {
    font-weight: 200;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.subscription-form p a {
    font-weight: 400;
    margin-top: 10px;
    color: var(--col2);
    cursor: pointer;
}


.pricetable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
  }
  
  .pricetable td {
    padding: 10px;
    border: 1px solid #dddddd;
    text-align: right;
  }
  
  .pricetable td:first-child {
    text-align: left;
    font-weight: bold;
  }
  
  .subscription-form button[type="submit"] {
    padding: 10px 20px;
    background-color: var(--col1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .subscription-form button[type="submit"]:hover {
    background-color:#4BB543;
  }