.news-bulletin {
    background-color: rgba(255, 255, 255, 0.116);
    color: white;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .news-marquee {
    display: inline-block;
    width: 100%;
  }
  