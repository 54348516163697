.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
    width: 100%;
    z-index: 100;
}

.left {
    display: flex;
    align-items: center;
}

.left img {
    height: 30px;
    object-fit: contain;
}

.center {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    gap: 30px;

}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    color: var(--col1);
    text-decoration: none;
    font-weight: 300;
    font-size: 20px;
    font-family: var(--font-family-default);
    padding: 10px;
    border: 2px dotted var(--text-col2);

}

.link:hover {
    border: 2px dotted var(--col2);

}


.right {
    display: flex;
    align-items: center;
    gap: 20px;

}

.hamburger {
    display: none;
    cursor: pointer;
    font-size: var(--icon-size1);
}

.user {
    color: var(--col1);
    font-size: 22px;
    cursor: pointer;

}

.loginButton {

    padding: 5px 20px;
    border: none;
    border: 1px solid var(--col1) ;
    color: var(--col1);
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
}

.mobileMenu {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background-color: #f8f9fa;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index:1000;
}

.mobileMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 10px;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--col1);
    font-size: var(--icon-size1);
    cursor: pointer;
}

@media (max-width: 768px) {
    .center {
        display: none;
    }

    .right {
        display: none;
    }

    .hamburger {
        display: block;
    }
}

/* 







.mobileLink {
    margin: 15px 0;
    text-decoration: none;
    color: #000;
    font-weight: bold;
}


 */