/* loginpage.css */

.loginpage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
}

.login-form {
    background-color: #fff;
    width: 90%;
    max-width: 700px; /* Maximum width for larger screens */
    min-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Reduced padding for smaller screens */
    border-radius: 20px;
}

.login-form h3 {
    margin-bottom: 15px; /* Reduced margin */
    font-size: 32px; /* Adjusted font size */
    font-weight: 300;
    text-align: center;
    color: var(--col1);
    border: 1px dashed black;
    padding: 10px;
}

.login-form h2 {
    margin-bottom: 15px; /* Reduced margin */
    font-size: 32px; /* Adjusted font size */
    font-weight: 300;
    text-align: center;
    color: var(--col1);
}

.login-form label {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    font-weight: 300;
}

.login-form form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 15px; /* Reduced margin */
    font-weight: 400;
    font-size: 16px;
}

.login-form button {
    background-color: var(--col1);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    font-size: 16px;
}

.login-form button:hover {
    background-color: var(--col1);
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}

.login-form p {
    font-weight: 300;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
}

.login-form p a {
    font-weight: 400;
    margin-top: 10px;
    color: var(--col1);
    cursor: pointer;
    text-decoration: underline;
}

.login-form .row {
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 0;
    margin-bottom: 10px;
}

.login-form .row input {
    margin-bottom: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .login-form {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .login-form h3, .login-form h2 {
        font-size: 28px; /* Further reduce font size */
        margin-bottom: 10px; /* Further reduce margin */
    }

    .login-form input {
        font-size: 14px; /* Smaller font size for inputs */
    }

    .login-form button {
        font-size: 14px; /* Adjust button font size */
        padding: 8px 16px; /* Adjust button padding */
    }

    .login-form p {
        font-size: 14px; /* Smaller font size for text */
    }
}

@media (max-width: 480px) {
    .login-form {
        width: 95%; /* Reduce width further on very small screens */
    }

    .login-form h3, .login-form h2 {
        font-size: 24px; /* Further decrease font size */
        margin-bottom: 8px; /* Adjust margin */
    }

    .login-form input {
        font-size: 12px; /* Smaller font size for inputs */
    }

    .login-form button {
        font-size: 12px; /* Adjust button font size */
        padding: 6px 12px; /* Adjust button padding */
    }

    .login-form p {
        font-size: 12px; /* Smaller font size for text */
    }
}
