.exam {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.exam .container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}

.examleft {
    flex: 1;
    flex-direction: column;
    margin-right: 20px;
}

.examright {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questionContainer {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    max-height: 500px;
    overflow-y: scroll;
}

.questionContainerR1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.questionContainerHR {
    border-bottom: 2px solid #ddd;
    margin: 10px 0;
}

.questionContainerR2 {
    text-align: center;
    margin: 20px 0;

}




.questionContainerR3 {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

}

.questionContainerR3 div {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.questionContainerR3 div label {
    font-size: 20px;

}

.questionContainerR4 {
    display: flex;
    justify-content: center;
}

.btnRow1,
.btnRow2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 5px;
}

.greenbtn,
.whitebtn,
.orangebtn,
.bluebtn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
}

.greenbtn {
    background-color: #28a745;
    color: white;
}

.whitebtn {
    background-color: white;
    color: black;
    border: 1px solid #ddd;
}

.orangebtn {
    background-color: #fd7e14;
    color: white;
}

.bluebtn {
    background-color: #007bff;
    color: white;
}

.backnext {
    display: flex;
    gap: 20px;
}

.backnext .whitebtn {
    /* margin: 0 10px; */
}

.infoContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    gap: 10px;

}

.infoItem {
    display: flex;
    justify-content: flex-start;
    width: 40%;
    gap: 10px;
    align-items: center;

}

.infoItemFull {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
    gap: 10px;
    align-items: center;

}

.infoLabel {
    font-size: 14px;
    color: #555;
}

.notvisited {
    background: url('https://nta.ac.in/img/QuizIcons/Logo1.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
}

.notanswered {
    background: url('https://nta.ac.in/img/QuizIcons/Logo2.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    color: white;

}

.answered {
    background: url('https://nta.ac.in/img/QuizIcons/Logo3.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    color: white;

}

.markedforreview {
    background: url('https://nta.ac.in/img/QuizIcons/Logo4.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    color: white;

}

.saveandmarkforreview {
    background: url('https://nta.ac.in/img/QuizIcons/Logo5.png');
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    color: white;
}


.questionsInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}




/* modal */


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensures the modal appears on top */
}

.modalContainer {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 1000px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    text-align: center;
}



.infoContainer2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
}

.infoItem2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal .head {
    font-weight: 700;
    font-size: 20px;
}

.infoContainer2 .vr {
    width: 1px;
    background-color: #333;
    height: 40px;
}

.infoItem2 span {
    margin: 5px 0;
}

.modalContainer p {
    margin: 20px 0;
    line-height: 1.5;
}

.modalContainer button {
    background-color: #fff;
    /* Button background color */
    color: #333;
    /* Button text color */
    border: 1px solid #333;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.modalContainer button:hover {
    background-color: #333;
    /* Darker shade for hover effect */
    color: white;
}



@media (max-width:1050px) {
    .infoContainer {
        flex-direction: column;
        justify-content: flex-start;
    }

    .infoItem {
        width: 100%;
    }
}


@media (max-width:1050px) {
    .container {
        flex-direction: column-reverse;
        align-items: center;

    }

    .infoContainer {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    .infoItem {
        width: max-content;
    }

    .infoItemFull {
        width: max-content;
    }

    .questionsInfo {
        margin: 10px 0px;
    }

    .examleft {
        align-items: center;
        margin: 0;
        padding: 0;
    }
}


@media (max-width:600px) {
    .examleft {
        align-items: center;
        margin: 0;
        padding: 0;
        width: 95%;
    }

    .btnRow1{
        flex-wrap: wrap;
    }
}