/* File: /styles/ExamLoginC1.module.css */

.examlogintopbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    gap: 20px;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
}

.examlogintopbar .examlogintopbarc11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    box-sizing: border-box; /* Ensures padding is included in width calculation */
}

.examlogintopbar .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    color: #495057; /* Grey color for icons */
    background-color: white; /* Light grey background for icons */
    border: 1px solid black;
    box-sizing: border-box; /* Ensures padding and border are included in width/height */
}

.examlogintopbar .c1Items p {
    margin: 5px 0;
    font-size: 14px;
    color: #212529; /* Dark grey text */
}

.examlogintopbar .c1Items span {
    font-weight: bold;
    color: orange; /* Darker grey for highlighted text */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .examlogintopbar {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: flex-start; /* Align items to the start */
        gap: 10px; /* Reduced gap between items */
    }

    .examlogintopbar .iconBox {
        width: 50px; /* Reduced width for smaller screens */
        height: 50px; /* Reduced height for smaller screens */
        margin-right: 10px; /* Reduced margin */
    }

    .examlogintopbar .c1Items p {
        font-size: 12px; /* Reduced font size for smaller screens */
    }

    .examlogintopbar .c1Items span {
        font-size: 14px; /* Adjust font size for highlighted text */
    }
}

@media (max-width: 480px) {
    .examlogintopbar .iconBox {
        width: 40px; /* Further reduced width */
        height: 40px; /* Further reduced height */
        margin-right: 5px; /* Further reduced margin */
    }

    .examlogintopbar .c1Items p {
        font-size: 10px; /* Further reduced font size */
    }

    .examlogintopbar .c1Items span {
        font-size: 12px; /* Adjust font size for highlighted text */
    }
}
