@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-default);
  font-weight:400;
  box-sizing: border-box;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
  --col1: #000000;
  --col2: #00000015;
  --text-col1: #273433;
  --text-col2: white;
  --icon-size1: 20px;

  --font-size-default: 16px;
  --font-family-default: "Raleway", sans-serif;
}