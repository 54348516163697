/* AllResults.css */

.table-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow-x: auto; /* Allows horizontal scrolling on small screens */
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  background-color: #f4f4f4;
}

.table-container th, .table-container td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #f2f2f2;
  color: #333;
}

.table-container tr:hover {
  background-color: #f5f5f5;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .table-container {
      margin: 10px;
      padding: 15px;
  }
}

@media (max-width: 768px) {
  .table-container th, .table-container td {
      padding: 10px; /* Slightly reduced padding for medium screens */
  }
}

@media (max-width: 480px) {
  .table-container th, .table-container td {
      padding: 8px; /* Further reduced padding for small screens */
      font-size: 14px; /* Adjust font size */
  }
  
  .table-container {
      margin: 5px;
      padding: 10px;
  }
}
