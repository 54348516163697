.viewtestbycategory {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
}

.inputbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
}

.inputbox input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 10px;
    background-color: #f1f1f1;
}

.inputbox svg {
    margin-left: 10px;
    cursor: pointer;
}

.categories {
    width: 35%;
}

.results {
    margin: 20px;
    text-align: center;
    width: 100%;
}

.results h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.allTests {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.testCard {
    border-collapse: collapse;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.testCard:hover {
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.733);
    cursor: pointer;
}

.testCard tbody {
    background-color: #fff;
    border: 1px solid #ddd;
}

.testCard tr {
    transition: background-color 0.2s;
}

.testCard tr:hover {
    background-color: #f9f9f9;
}

.testCard .label {
    font-weight: bold;
    color: #555;
    text-align: left;
}

.testCard .testName {
    color: #007bff;
    font-weight: bold;
}

.testCard td {
    padding: 10px 20px;
    /* border-bottom: 1px solid #ddd; */
    vertical-align: top;
}

.testCard td:last-child {
    text-align: right;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
        max-width: 100%;
        margin: 10px;
        gap: 20px;
    }

    .inputbox,
    .categories {
        width: 100%;
        margin-top: 10px;
    }
}