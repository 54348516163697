/* TestResult.css */

.testResult {
    font-family: Arial, sans-serif;
    /* padding: 20px; */
    background-color: #f5f5f5;
    min-height: 100vh;
}


.testResultDetails {
    max-width: '95%';
    margin: 0 auto;
    background: #fff;
    padding: 50px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testResultDetails h3 {
    margin-top: 0;
    color: #333;
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.testResultDetails p {
    margin: 5px 0;
    color: #555;
}

.testResultDetails h4 {
    margin-top: 20px;
    color: #333;
    font-size: 50px;
    text-align: center;
    padding: 20px 0px;
    border-top: 1px solid #333;

    /* border-bottom: 1px solid #333; */
}

.testResultDetails ul {
    list-style-type: none;
    padding: 0;
}

.testResultDetails li {
    /* border: 2px dotted black; */
    padding: 10px 0;
    display: flex;
    gap: 10px;
    border-bottom: none;

}

.testResultDetails img {
    max-width: 50%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.testResultDetails li:last-child {
    /* border: 2px dotted black; */

}



.loading,
.error {
    text-align: center;
    font-size: 18px;
    color: #ff0000;
    margin-top: 20px;
}



.testResultDetails table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.testResultDetails table,
.testResultDetails th,
.testResultDetails td {
    border: 1px solid #ddd;
}

.testResultDetails th,
.testResultDetails td {
    padding: 8px;
    text-align: left;
    font-size: 20px;
}

.testResultDetails tr:nth-child(even) {
    background-color: #f2f2f2;
}

.testResultDetails tr:hover {
    background-color: #ddd;
}


@media (max-width:700px) {

    .testResultDetails li {
        /* border: 2px dotted black; */
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-bottom: none;

    }

    .testResultDetails img {
        max-width: 100%;
        height: auto;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

}