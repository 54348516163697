/* File: /styles/Instructions.module.css */

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9fa; /* Light grey background */
}

.c2 {
    background-color: white; /* Blue background color */
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.instructions {
    background-color: #ffffff; /* White background color */
    padding: 20px; /* Adjusted padding for smaller screens */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    max-width: 100%; /* Full width on smaller screens */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Increased gap for better spacing */
}

.instructions h1 {
    font-size: 24px;
    color: #343a40; /* Dark grey text */
    margin-bottom: 20px;
    text-align: center;
}

/* Responsive font sizes */
.leftUnderlined {
    text-decoration: underline;
    font-weight: bold;
    font-size: 16px;
    color: #495057;
    margin-top: 10px;
}

.point {
    font-size: 14px;
    color: #495057; /* Grey text */
    margin-bottom: 10px;
    line-height: 1.6;
    display: flex;
    align-items: center;
    gap: 10px;
}

.leftPadding {
    padding-left: 20px;
}

.hr {
    border: 0;
    height: 1px;
    background: #dee2e6; /* Light grey line */
    margin: 20px 0;
}

.brown {
    color: #8b4513; /* Brown text */
}

.greenbtn {
    background-color: #28a745; /* Green background */
    color: white;
    padding: 10px 20px; /* Adjusted padding for smaller screens */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: auto; /* Ensures button size adjusts to text */
    align-self: center;
}

.greenbtn:hover {
    background-color: #218838; /* Darker green on hover */
}

.inst1{
    max-width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .instructions {
        padding: 15px; /* Adjusted padding for tablets */
    }

    .instructions h1 {
        font-size: 20px; /* Reduced font size for smaller screens */
    }

    .leftUnderlined {
        font-size: 14px; /* Reduced font size */
    }

    .point {
        font-size: 12px; /* Reduced font size */
    }

    .greenbtn {
        padding: 8px 20px; /* Adjusted padding for smaller screens */
        font-size: 12px; /* Reduced font size */
    }
}

@media (max-width: 480px) {
    .instructions {
        padding: 10px; /* Further reduced padding for mobile screens */
    }

    .instructions h1 {
        font-size: 18px; /* Further reduced font size */
    }

    .leftUnderlined {
        font-size: 12px; /* Further reduced font size */
    }

    .point {
        font-size: 10px; /* Further reduced font size */
    }

    .greenbtn {
        padding: 6px 15px; /* Further adjusted padding */
        font-size: 10px; /* Further reduced font size */
    }
}
