/* examloginc1.css */

.examloginc1 {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background-color: #012B55;
}

.examloginc1 .c1 {
    width: 100%;
    background-color: white;
}

.examloginc1 .c2 {
    background-color: #012B55;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 50px;
}

.examloginc1 .loginContainer {
    background-color: #ffffff;
    /* White background color */
    padding: 20px; /* Reduced padding for smaller screens */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Slight shadow for depth */
    width: 90%; /* Adjusted width to be responsive */
    max-width: 400px; /* Maximum width for larger screens */
    text-align: center;
}

.examloginc1 .loginContainer h1 {
    margin-bottom: 15px; /* Reduced margin */
    font-size: 20px; /* Adjusted font size */
    color: #343a40;
    /* Dark grey text */
}

.examloginc1 .hr {
    border: 0;
    height: 1px;
    background: #dee2e6;
    /* Light grey line */
    margin-bottom: 15px; /* Reduced margin */
}

.examloginc1 .loginContainer label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #495057;
    /* Grey text */
    text-align: left;
}

.examloginc1 .loginContainer input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px; /* Reduced margin */
    border: 1px solid #ced4da;
    /* Light grey border */
    border-radius: 4px;
    background-color: #e9ecef;
    /* Light grey background for disabled input */
    color: #6c757d;
    /* Medium grey text */
}

.examloginc1 .loginContainer button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #012B55;
    /* Blue background */
    color: #ffffff;
    /* White text */
    font-size: 14px; /* Adjusted font size */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.examloginc1 .loginContainer button:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

.examloginc1 .loginContainer span {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: orange;
    /* Medium grey text */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .examloginc1 .loginContainer {
        padding: 15px; /* Reduced padding for medium screens */
        width: 95%; /* Adjusted width for smaller screens */
    }

    .examloginc1 .loginContainer h1 {
        font-size: 18px; /* Smaller font size */
    }

    .examloginc1 .hr {
        margin-bottom: 10px; /* Reduced margin */
    }

    .examloginc1 .loginContainer input {
        margin-bottom: 10px; /* Reduced margin */
    }

    .examloginc1 .loginContainer button {
        font-size: 14px; /* Adjusted font size */
    }
}

@media (max-width: 480px) {
    .examloginc1 .loginContainer {
        padding: 10px; /* Further reduced padding */
        width: 95%; /* Further reduced width */
    }

    .examloginc1 .loginContainer h1 {
        font-size: 16px; /* Further reduced font size */
    }

    .examloginc1 .hr {
        margin-bottom: 8px; /* Further reduced margin */
    }

    .examloginc1 .loginContainer input {
        margin-bottom: 8px; /* Further reduced margin */
        font-size: 14px; /* Adjusted font size */
    }

    .examloginc1 .loginContainer button {
        font-size: 12px; /* Smaller font size */
    }

    .examloginc1 .loginContainer span {
        font-size: 10px; /* Smaller font size */
    }
}
