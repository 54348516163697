/* File: /styles/ExamLoginC1.module.css */

.ExamTopbarc1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    gap: 20px;
    box-shadow: 0 0 10px 0 rgb(224, 224, 224);
}

.ExamTopbarc11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* margin: 10px 0; */
    width: 70%;
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    
}

.ExamTopbariconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    color: #495057; /* Grey color for icons */
    background-color: white; /* Light grey background for icons */
    border: 1px solid black;
}

.ExamTopbarc1Items p {
    margin: 5px 0;
    font-size: 14px;
    color: #212529; /* Dark grey text */
}

.ExamTopbarc1Items span {
    font-weight: bold;
    color: orange; /* Darker grey for highlighted text */
}
