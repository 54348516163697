.dailyPracticeProblems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;
    border-radius: 4px;
    justify-content: center;
    gap: 5px;
    /* background-color: aqua; */
    width: 100%; /* Ensure it takes full width of the parent */
    height: 100%; /* Ensure it takes full height of the parent */
  }
  
  .dailyPracticeProblems h2 {
    margin-bottom: 1rem;
    font-size: 50px;
    font-family: var(--font-family-default);
    font-weight: 500;
    line-height: 1em;
  }
  
  .dailyPracticeProblems p {
    font-size: 16px;
    color: var(--font-color-default);
    word-spacing: 10px;
    text-align: justify;
  }
  
  .dailyPracticeProblems .btn1 {
    background-color: var(--col2);
    color: var(--col1);
    border: 1px solid var(--col2);
    border-radius: 20px;
    cursor: pointer;
    width: max-content;
    padding: 2px 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .dailyPracticeProblems .btn2 {
    padding: 0.75rem 1.5rem;
    background-color: var(--col1);
    color: #fff;
    border: 1px solid var(--col1);
    cursor: pointer;
    width: 30%;
    font-size: 12px;
  }
  
  .examType {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }
  
  .examType .form {
    width: 60%;
  }
  @media (max-width: 800px) {
    .dailyPracticeProblems {
      display: flex;
      flex-direction: column;
      padding: 3rem 1rem;
      border-radius: 4px;
     
    }
    
  }


  @media (max-width: 800px) {
    .dailyPracticeProblems h2 {
      text-align: center;
    }
  }