/* User Profile Styles */

.user-profile {
    max-width: 1000px;
    margin: auto;
    padding: 50px; /* Adjusted padding for smaller screens */
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.801);
}

.user-profile h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--col1);
    font-size: 30px; /* Adjusted font size for smaller screens */
    font-weight: 400;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-info div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.user-info label {
    color: var(--col3);
    font-size: 16px; /* Adjusted font size for smaller screens */
}

.user-info span {
    margin-left: 10px;
    font-size: 16px; /* Adjusted font size for smaller screens */
}

.subscription-info {
    background-color: black;
    padding: 10px;
    color: white;
    margin-top: 20px;
    border-radius: 10px;
}

.subscription-info h3 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-size: 18px; /* Adjusted font size for smaller screens */
    font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .user-profile {
        padding: 30px; /* Reduced padding for smaller screens */
    }

    .user-profile h2 {
        font-size: 24px; /* Further reduced font size */
    }

    .user-info label, .user-info span {
        font-size: 14px; /* Smaller font size for labels and spans */
    }

    .subscription-info h3 {
        font-size: 16px; /* Adjusted font size */
    }
}

@media (max-width: 480px) {
    .user-profile {
        padding: 20px; /* Further reduced padding */
    }

    .user-profile h2 {
        font-size: 20px; /* Smaller font size */
    }

    .user-info label, .user-info span {
        font-size: 12px; /* Further reduced font size */
    }

    .subscription-info h3 {
        font-size: 14px; /* Smaller font size */
    }
}
